<template>
  <div style="height: inherit">
    <!-- Overlay -->

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="q"
              placeholder="Masukkan Judul e-book"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-3">
      <div class="d-flex align-items-center text-body">
        <h4>Paling Banyak Dicari</h4>
      </div>
      <div class="d-flex align-items-center text-body">
        <b-link :to="{ name: 'ebook-banyak-dicari' }">
          <h6 class="text-primary">Lihat Semua</h6>
        </b-link>
      </div>
    </div>
    <!-- Prodcuts -->

    <b-overlay :show="loading">
      <div class="text-center" v-if="data.length < 1">
        <b-img
          :src="require('@/assets/images/elp/empty.png')"
          fluid
          width="200px"
        />
        <h5 class="text-primary">E-book tidak ditemukan</h5>
      </div>
      <section class="mt-2" v-else>
        <b-row>
          <b-col
            cols="6"
            sm="6"
            md="4"
            lg="3"
            v-for="(item, i) in currentPageItems"
            :key="i"
          >
            <b-card class="ecommerce-card" no-body>
              <div class="">
                <b-link
                  :to="{
                    name: 'ebook-detail',
                    params: { slug: item.slug, status: 'belum-dimiliki' },
                  }"
                >
                  <b-img
                    :alt="`${item.name}-${item.id}`"
                    fluid
                    :src="apiFile + item.photo"
                    class="card-img-top"
                    style="max-height: 180px; object-fit: cover"
                  />
                </b-link>
              </div>

              <!-- item Details -->
              <b-card-body>
                <div class="item-wrapper">
                  <div>
                    <small>UTBK</small>
                  </div>
                </div>
                <h6 class="item-name" style="max-height: 50px; overflow: auto">
                  <b-link
                    class="text-body"
                    :to="{
                      name: 'ebook-detail',
                      params: { slug: item.slug, status: 'belum-dimiliki' },
                    }"
                  >
                    {{ item.name }}
                  </b-link>
                </h6>
                <b-card-text class="item-description mt-4">
                  <h6
                    style="position: absolute; bottom: 0"
                    class="text-primary pb-2"
                    v-if="item.is_free == 1"
                  >
                    Gratis
                  </h6>
                  <h6
                    style="position: absolute; bottom: 0"
                    class="text-primary pb-2"
                    v-else
                  >
                    Rp. {{ formatRupiah(item.price) }}
                  </h6>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </section>
      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalData"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-overlay>

    <div class="d-flex justify-content-between align-items-center mt-3">
      <div class="d-flex align-items-center text-body">
        <h4>Gratis Untukmu</h4>
      </div>
      <div class="d-flex align-items-center text-body">
        <b-link :to="{ name: 'ebook-gratis' }">
          <h6 class="text-primary">Lihat Semua</h6>
        </b-link>
      </div>
    </div>
    <!-- Prodcuts -->
    <b-overlay :show="loadingG">
      <div class="text-center" v-if="dataGratis.length < 1">
        <b-img
          :src="require('@/assets/images/elp/empty.png')"
          fluid
          width="200px"
        />
        <h5 class="text-primary">E-book tidak ditemukan</h5>
      </div>
      <section class="mt-2" v-else>
        <b-row>
          <b-col
            cols="6"
            sm="6"
            md="4"
            lg="3"
            v-for="(item, i) in dataGratis"
            :key="i"
          >
            <b-card class="ecommerce-card" no-body>
              <div class="item-img text-center">
                <b-link
                  :to="{
                    name: 'ebook-detail',
                    params: { slug: item.slug, status: 'belum-dimiliki' },
                  }"
                >
                  <b-img
                    :alt="`${item.name}-${item.id}`"
                    fluid
                    :src="apiFile + item.photo"
                    class="card-img-top"
                    style="max-height: 180px; object-fit: cover"
                  />
                </b-link>
              </div>

              <!-- item Details -->
              <b-card-body>
                <div class="item-wrapper">
                  <div>
                    <small>UTBK </small>
                  </div>
                </div>
                <h6 class="item-name">
                  <b-link
                    class="text-body"
                    :to="{
                      name: 'ebook-detail',
                      params: { slug: item.slug, status: 'belum-dimiliki' },
                    }"
                  >
                    {{ item.name }}
                  </b-link>
                </h6>
                <b-card-text class="item-description mt-4">
                  <h6
                    style="position: absolute; bottom: 0"
                    class="text-primary"
                    v-if="item.price == 0"
                  >
                    Gratis
                  </h6>
                  <h6
                    style="position: absolute; bottom: 0"
                    class="text-primary"
                    v-else
                  >
                    Rp. {{ formatRupiah(item.price) }}
                  </h6>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </section>

      <!-- Pagination Gratis -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filtersG.page"
              :total-rows="totalDataGratis"
              :per-page="filtersG.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BAlert,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BOverlay,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
  },
  computed: {
    currentPageItems() {
      let lengthAll = this.data.length;
      this.nbPages = 0;
      for (let i = 0; i < lengthAll; i = i + this.filters.perPage) {
        this.paginated_items[this.nbPages] = this.data.slice(
          i,
          i + this.filters.perPage
        );
        this.nbPages++;
      }

      return this.paginated_items[this.filters.page - 1];
    },
    currentPageItemsG() {
      let lengthAll = this.dataGratis.length;
      this.nbPagesG = 0;
      for (let i = 0; i < lengthAll; i = i + this.filtersG.perPage) {
        this.paginated_itemsG[this.nbPagesG] = this.dataGratis.slice(
          i,
          i + this.filtersG.perPage
        );
        this.nbPagesG++;
      }

      return this.paginated_itemsG[this.filtersG.page - 1];
    },
  },
  data() {
    return {
      loading: false,
      loadingG: false,
      filterOptions: null,
      sortBy: null,
      sortByOptions: null,
      totalData: null,
      totalDataGratis: null,
      data: [],
      dataGratis: [],
      q: null,
      filters: {
        page: 1,
        perPage: 4,
      },
      filtersG: {
        page: 1,
        perPage: 4,
      },
      paginated_items: {},
      currentPageIndex: 0,
      nbPages: 0,
      paginated_itemsG: {},
      currentPageIndexG: 0,
      nbPagesG: 0,
    };
  },
  watch: {
    q: {
      handler(value) {
        if (this.q == "") {
          this.q = null;
          this.getData();
          this.getDataGratis();
        } else {
          this.getData();
          this.getDataGratis();
        }
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      this.loading = true;
      let params = {
        search: this.q,
        is_free: 0,
      };

      this.$store
        .dispatch("e-book/index", params)
        .then((res) => {
          this.loading = false;
          this.data = res.data.data;
          this.totalData = this.data.length;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getDataGratis() {
      this.loadingG = true;
      let params = {
        search: this.q,
        is_free: 1,
      };
      this.$store
        .dispatch("e-book/index", params)
        .then((res) => {
          this.loadingG = false;
          this.dataGratis = res.data.data;
          this.totalDataGratis = this.dataGratis.length;
        })
        .catch((err) => {
          this.loadingG = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    this.getData();
    this.getDataGratis();
  },
  // setup() {
  //   const { filters, filterOptions, sortBy, sortByOptions } = useShopFiltersSortingAndPagination();

  //   const { totalProducts } = useShopUi();

  //   const { products, fetchProducts } = useShopRemoteData();

  //   const fetchShopProducts = () => {
  //     fetchProducts({
  //       q: filters.value.q,
  //       sortBy: sortBy.value.value,
  //       page: filters.value.page,
  //       perPage: filters.value.perPage,
  //     }).then((response) => {
  //       products.value = response.data.products.slice(0, 4);
  //       totalProducts.value = response.data.total;
  //     });
  //   };

  //   fetchShopProducts();

  //   watch(
  //     [filters, sortBy],
  //     () => {
  //       fetchShopProducts();
  //     },
  //     {
  //       deep: true,
  //     }
  //   );

  //   return {
  //     filters,
  //     filterOptions,
  //     sortBy,
  //     sortByOptions,
  //     totalProducts,
  //     products,
  //   };
  // },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
