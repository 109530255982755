var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('div', {
    staticClass: "ecommerce-searchbar mt-1"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    staticClass: "search-product",
    attrs: {
      "placeholder": "Masukkan Judul e-book"
    },
    model: {
      value: _vm.q,
      callback: function callback($$v) {
        _vm.q = $$v;
      },
      expression: "q"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "text-muted",
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-3"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center text-body"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'ebook-banyak-dicari'
      }
    }
  }, [_c('h6', {
    staticClass: "text-primary"
  }, [_vm._v("Lihat Semua")])])], 1)]), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.data.length < 1 ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/elp/empty.png'),
      "fluid": "",
      "width": "200px"
    }
  }), _c('h5', {
    staticClass: "text-primary"
  }, [_vm._v("E-book tidak ditemukan")])], 1) : _c('section', {
    staticClass: "mt-2"
  }, [_c('b-row', _vm._l(_vm.currentPageItems, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "cols": "6",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('b-card', {
      staticClass: "ecommerce-card",
      attrs: {
        "no-body": ""
      }
    }, [_c('div', {}, [_c('b-link', {
      attrs: {
        "to": {
          name: 'ebook-detail',
          params: {
            slug: item.slug,
            status: 'belum-dimiliki'
          }
        }
      }
    }, [_c('b-img', {
      staticClass: "card-img-top",
      staticStyle: {
        "max-height": "180px",
        "object-fit": "cover"
      },
      attrs: {
        "alt": "".concat(item.name, "-").concat(item.id),
        "fluid": "",
        "src": _vm.apiFile + item.photo
      }
    })], 1)], 1), _c('b-card-body', [_c('div', {
      staticClass: "item-wrapper"
    }, [_c('div', [_c('small', [_vm._v("UTBK")])])]), _c('h6', {
      staticClass: "item-name",
      staticStyle: {
        "max-height": "50px",
        "overflow": "auto"
      }
    }, [_c('b-link', {
      staticClass: "text-body",
      attrs: {
        "to": {
          name: 'ebook-detail',
          params: {
            slug: item.slug,
            status: 'belum-dimiliki'
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('b-card-text', {
      staticClass: "item-description mt-4"
    }, [item.is_free == 1 ? _c('h6', {
      staticClass: "text-primary pb-2",
      staticStyle: {
        "position": "absolute",
        "bottom": "0"
      }
    }, [_vm._v(" Gratis ")]) : _c('h6', {
      staticClass: "text-primary pb-2",
      staticStyle: {
        "position": "absolute",
        "bottom": "0"
      }
    }, [_vm._v(" Rp. " + _vm._s(_vm.formatRupiah(item.price)) + " ")])])], 1)], 1)], 1);
  }), 1)], 1), _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalData,
      "per-page": _vm.filters.perPage,
      "first-number": "",
      "align": "center",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "page", $$v);
      },
      expression: "filters.page"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-3"
  }, [_vm._m(1), _c('div', {
    staticClass: "d-flex align-items-center text-body"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'ebook-gratis'
      }
    }
  }, [_c('h6', {
    staticClass: "text-primary"
  }, [_vm._v("Lihat Semua")])])], 1)]), _c('b-overlay', {
    attrs: {
      "show": _vm.loadingG
    }
  }, [_vm.dataGratis.length < 1 ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/elp/empty.png'),
      "fluid": "",
      "width": "200px"
    }
  }), _c('h5', {
    staticClass: "text-primary"
  }, [_vm._v("E-book tidak ditemukan")])], 1) : _c('section', {
    staticClass: "mt-2"
  }, [_c('b-row', _vm._l(_vm.dataGratis, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "cols": "6",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('b-card', {
      staticClass: "ecommerce-card",
      attrs: {
        "no-body": ""
      }
    }, [_c('div', {
      staticClass: "item-img text-center"
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'ebook-detail',
          params: {
            slug: item.slug,
            status: 'belum-dimiliki'
          }
        }
      }
    }, [_c('b-img', {
      staticClass: "card-img-top",
      staticStyle: {
        "max-height": "180px",
        "object-fit": "cover"
      },
      attrs: {
        "alt": "".concat(item.name, "-").concat(item.id),
        "fluid": "",
        "src": _vm.apiFile + item.photo
      }
    })], 1)], 1), _c('b-card-body', [_c('div', {
      staticClass: "item-wrapper"
    }, [_c('div', [_c('small', [_vm._v("UTBK ")])])]), _c('h6', {
      staticClass: "item-name"
    }, [_c('b-link', {
      staticClass: "text-body",
      attrs: {
        "to": {
          name: 'ebook-detail',
          params: {
            slug: item.slug,
            status: 'belum-dimiliki'
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('b-card-text', {
      staticClass: "item-description mt-4"
    }, [item.price == 0 ? _c('h6', {
      staticClass: "text-primary",
      staticStyle: {
        "position": "absolute",
        "bottom": "0"
      }
    }, [_vm._v(" Gratis ")]) : _c('h6', {
      staticClass: "text-primary",
      staticStyle: {
        "position": "absolute",
        "bottom": "0"
      }
    }, [_vm._v(" Rp. " + _vm._s(_vm.formatRupiah(item.price)) + " ")])])], 1)], 1)], 1);
  }), 1)], 1), _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalDataGratis,
      "per-page": _vm.filtersG.perPage,
      "first-number": "",
      "align": "center",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filtersG.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filtersG, "page", $$v);
      },
      expression: "filtersG.page"
    }
  })], 1)], 1)], 1)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center text-body"
  }, [_c('h4', [_vm._v("Paling Banyak Dicari")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center text-body"
  }, [_c('h4', [_vm._v("Gratis Untukmu")])]);
}]

export { render, staticRenderFns }